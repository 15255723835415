import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Dataview } from '../../../dataQuest.constants';
import { DataViewService } from './dataview.service';
import { DetailsService } from '../details/details.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'edit-dataview',
  templateUrl: './edit-dataview.component.html',
  styleUrls: ['./edit-dataview.component.scss']
})

export class EditDataviewComponent implements OnInit {

  viewId: any;
  addtagFlag: any = false;
  keyword = 'name';
  @ViewChild('auto') auto:any;
  viewForm: FormGroup;
  viewFormCopy: FormGroup;
  autocompleteText:any="";
  tagsByViewId:any=[]
  successMessage: string = null;
  errorMessageSubmit: string = null;

  // Hardcoded data starts

  editDVHeaders: Array<any> = [ 'Field Name', 'Display Name', 'isFilter?', 'isHidden?'];
  

  item: Dataview | null = null;
  itemId: string | null = null;
  allTags: any = [];
  fieldsArray: any;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location,
    private dataViewService: DataViewService,
    private detailsService: DetailsService,
    private spinner: NgxSpinnerService,
    private utilService: UtilService,
    private fb: FormBuilder,
    public dialog: MatDialog) {}
  
  ngOnInit(): void {
    this.viewId = this.route.snapshot.paramMap.get('id');

    //---------get Veiw Data using viewId-----
    this.getViewDataById();
  }


  getViewDataById() {
    this.dataViewService.getDataviewById(this.viewId).subscribe({
    next: (res: any) => {
      if (res.code === 400) {
        this.errorMessageSubmit = res?.result?.error || res?.message;          
        this.spinner.hide();
        setTimeout(() => {
          this.errorMessageSubmit = null;
          this.utilService.setManagedView(true); 
          this.router.navigate(['/admin-settings']);
        }, 3000);
      } else {
        const response  = res.result;
        this.item = response;
        this.viewId = this.item.viewId;
        this.getAllTags();
        this.initForm();
        this.fieldsControls();                             
        this.errorMessageSubmit = null;        
        this.spinner.hide();                                
      }
    },
    error: (err: any) => {
      this.spinner.hide();      
      this.errorMessageSubmit = err || err?.result?.error; 
      this.spinner.hide();
      setTimeout(() => {
        this.errorMessageSubmit = null;
        this.utilService.setManagedView(true); 
        this.router.navigate(['/admin-settings']);
      }, 3000);
      this.utilService.goToTop();
    }
   });
  }

  initForm() {
    
    this.viewForm = new FormGroup({
      userId: new FormControl(sessionStorage.getItem('userId')),
      viewId: new FormControl(this.item.viewId),      
      viewName: new FormControl(this.item.viewName),
      description: new FormControl(this.item.viewDescription),
      tags: new FormArray([]),
      fields: new FormArray([])
    });
    
    //------Fill Assigned Tags-----
    let index = 0;
    this.item.assignedTags.forEach( (assignedTag: any) =>{
      const fg =this.fb.group({
        tagId: new FormControl(assignedTag.id),
        tagName: new FormControl(assignedTag.tagName),
        status: new FormControl('Existing')
      });
      
      (<FormArray>this.viewForm.get('tags')).insert(index, fg);
      index++;
    })

    //--------Fill View Fields------
    let fieldIndex = 0;
    this.item.viewFields.forEach((row: any) => {
      const fg =this.fb.group({
        fieldId: new FormControl(row.id),
        displayName: new FormControl(row.displayName),
        fieldName: new FormControl(row.fieldName),
        isFilter: new FormControl(row.isFilter),
        isHidden: new FormControl(row.isHidden),        
      });

      (<FormArray>this.viewForm.get('fields')).insert(fieldIndex, fg);
      fieldIndex++;            
    });
  }


  fieldsControls(): any{
   this.fieldsArray = (<FormArray>this.viewForm.get('fields')).controls;
   return this.fieldsArray;
  }

  
  
  save() {
    let data = this.viewForm.value;
    console.log(data);
    this.spinner.show();

    this.dataViewService.saveView(data).subscribe({
      next: (res: any) => {
        if (res.code === 400) {
          this.errorMessageSubmit = res?.result?.message || res?.message;          
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
        } else {
          const savedraftres = res;                              
          this.errorMessageSubmit = null;
          this.successMessage = `${savedraftres.result}`;
          this.spinner.hide();
          this.utilService.goToTop();
          setTimeout(() => {
            this.successMessage = null;
            this.utilService.setManagedView(true); 
            this.router.navigate(['/admin-settings']);
          }, 3000);
        }
      },
      error: (err: any) => {
        this.spinner.hide();
        this.errorMessageSubmit =  err; 
        this.spinner.hide();
        setTimeout(() => {
          this.errorMessageSubmit = null;
        }, 3000);
        this.utilService.goToTop();
      },
      });
  }

  
  goBack() {
    if(this.viewForm.touched && !this.viewForm.pristine){
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Confirmation',
          message: ' This action will clear all unsaved data. Do you want to proceed?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result){ 
          this.utilService.setManagedView(true);    
          this.router.navigate(['/admin-settings']);   
        }                        
      });
    } else if(!this.viewForm.touched  || (this.viewForm.touched && this.viewForm.pristine)){
      this.utilService.setManagedView(true); 
      this.router.navigate(['/admin-settings']);    
   }
  }


  removeTag(assignedTag:any){
    let assignedTagsCopy = this.item.assignedTags;   
    this.item.assignedTags = [...assignedTagsCopy.filter( tag => {
          return tag.tagName != assignedTag.tagName;
    }) ]  

    this.removeTagForm(assignedTag);
  }

  removeTagForm(assignedTag: any) {
    const id = assignedTag.id;
    let formArray  = (<FormArray>this.viewForm.get('tags'));
  
    formArray.value.forEach((element: any) => {
      if(element.tagId == id && id != 0)
      {        
        let i = (<FormArray>this.viewForm.get('tags')).value.indexOf(element);
        if(element['status'] == 'Added')
        {
         
         this.removeTagFormControlWithValue(id);                               
        } else {      
          element['status'] = 'Removed';                    
          (<FormArray>this.viewForm.get('tags')).controls[i].setValue(element);
        }

      } else if(element.tagName == assignedTag.tagName && id == 0) {  
          this.removeTagFormControlWithValue(0);                                                                               
      }
    });      
  }


  removeTagFormControlWithValue(id: any) {
    let k = 0;
      (<FormArray>this.viewForm.get('tags')).controls.forEach(el1 => {
          console.log(el1.value.tagId);
          if(el1.value.tagId == id){
            (<FormArray>this.viewForm.get('tags')).controls.splice(k, 1);
            (<FormArray>this.viewForm.get('tags')).value.splice(k, 1);
          } else {
            k = k+1;
          }
      });
  }

  getAllTags(){
    this.detailsService.getAllTags().subscribe(res=>{
      if(res){
        this.allTags=res.result.map((x:any)=>({id:x.id,name:x.tagName})).filter((md:any) => 
          this.item.assignedTags.every((fd:any) => fd.id !== md.id))
      }
    })
  }

  showAddInput(){
    this.addtagFlag=true;
  }

  addNewTag(e:any, flag=false){    
    if(flag){
      this.addTags(e,true)
    }else{
      this.addTags(e.target.value,true)
    }
  } 

  addTags(item:any,addNew=false){
    const tag = addNew ? item : item.name;
    const id = addNew ? 0 : item.id;
    let tagObj;
    if(id == 0){
      tagObj = { tagName: tag};
    } else {
       tagObj = {id: id, tagName: tag};
    }
    this.item.assignedTags.push(tagObj);
    this.autocompleteText="";
    this.addtagFlag = false;
    this.addTagForm(tagObj, 'Added');
  }


  addTagForm(item: any, action: any) {
    const fg =this.fb.group({
      tagId: new FormControl(item.id),
      tagName: new FormControl(item.tagName),
      status: new FormControl(action)
    });
    
    let formArray = (<FormArray>this.viewForm.get('tags'));
    formArray.push(fg);
  }

  getTagsByView() {
    this.addtagFlag = false;
    if (!this.viewId) {
      this.detailsService.viewId.subscribe(res => {
        this.viewId = res.viewId;
        this.detailsService.getTagsByView(this.viewId).subscribe(res => {
          if (res) {
            this.tagsByViewId = res.result       
            this.item.assignedTags = [...this.tagsByViewId];             
            this.autocompleteText="";
          }
          this.getAllTags()
        })
      })
    } else {
      this.detailsService.getTagsByView(this.viewId).subscribe(res => {
        if (res) {
          this.tagsByViewId = res.result
          this.item.assignedTags = [...this.tagsByViewId]; 
          this.autocompleteText="";
        }
        this.getAllTags()
      })
    }
  }

  selectEvent(item: any) {
    this.addTags(item);
  }

  setText(e:any){
    this.autocompleteText=e.target.value;
  }

  reset(){
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Confirmation',
        message: ' This action will clear all the records.Are you sure you want to cancel?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){        
        this.getViewDataById();   
      }                        
    });
  }
}
