
<div class="appContainer">
    <div class="wrpr">
        <h1>Data Quest</h1>
        <form action="" [formGroup]="viewForm" class="contentCard" *ngIf="item">
            <div class="pageHead">
                <button class="backBtn" (click)="goBack()">
                    <img src="/assets/images/backBtn.svg" alt="">
                </button>
                <div class="iconHeading">
                    <img src="/assets/images/settings-green.svg" alt="">
                    <h1>Edit Dataview: <span>{{item.viewName}}</span></h1>
                </div>
            </div>
            <div class="formControl">
                <label for="agName" class="required">Dataview Name</label>
                <input type="text" class="inputField"  placeholder="Type here" id="viewName" formControlName="viewName"  required pattern="^[a-zA-Z0-9\s]+$">                                                                
                <div *ngIf="viewForm.controls['viewName'].invalid && (viewForm.controls['viewName'].dirty || viewForm.controls['viewName'].touched)" class="errorText">
                    <div *ngIf="viewForm.controls['viewName'].errors?.['required']">ViewName is required</div>
                    <div *ngIf="viewForm.controls['viewName'].errors?.['pattern']" class="errorText">ViewName should contains only alphanumeric</div>
                </div>
            </div>
            <div class="formControl pullUp">
                <label for="agName" class="required">Description</label>
                <textarea class="inputField" placeholder="Type here" required id="description"  formControlName="description" pattern="^[a-zA-Z0-9\s]+$"></textarea>
                <button class="resetTextarea" (click)="viewForm.controls['description'].setValue('')">Clear</button>
                <div *ngIf="viewForm.controls['description'].invalid && (viewForm.controls['description'].dirty || viewForm.controls['description'].touched)" class="errorText">
                    <div *ngIf="viewForm.controls['description'].errors?.['required']">Description is required</div>
                    <div *ngIf="viewForm.controls['description'].errors?.['pattern']" class="errorText">Description should contains only alphanumeric</div>
                </div>
            </div>
            <div class="formControl pullUp">
                <label for="agName">Related Tags:</label>
                <div class="tagSet">
                    <div class="tag" *ngFor="let assignedTag of item.assignedTags">
                        {{assignedTag.tagName}}
                        <img src="/assets/images/closeIcon-16x16.svg" alt="" (click)="removeTag(assignedTag)">
                    </div>
                
                <button class="addTagBtn" *ngIf="!addtagFlag" (click)="showAddInput()">
                    <img src="/assets/images/closeIcon-16x16.svg" alt="" style="transform: rotate(45deg);">
                    ADD TAG
                </button>
                <div *ngIf="addtagFlag"  class="tag-autocomplete">
                    <ng-autocomplete [data]="allTags" [searchKeyword]="keyword" placeholder="Enter the Tag Name"
                    (selected)="selectEvent($event)"
                    [itemTemplate]="itemTemplate" class="autoComplete" [notFoundTemplate]="notFoundTemplate" #auto
                    (keyup.enter)="addNewTag($event)" (keyup)="setText($event)">
                    </ng-autocomplete> 
                    
                    <ng-template #itemTemplate let-item>
                        <div>
                          <a [innerHTML]="item.name"></a> <span><img src="../../../../assets/images/addTagIcon.svg" /></span>
                        </div>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div>
                          <a  (click)="addNewTag(autocompleteText,true)" class="notFoundTag">
                             <span>Create "{{autocompleteText}}"</span>
                            <span><img src="../../../../assets/images/addTagIcon.svg" /></span></a> 
                        </div>
                      </ng-template>
                </div>  
            </div>    
            </div>
            <div class="formControl">                
                <div class="tableCont">
                    <div class="tableHead">
                        <div class="thDiv" *ngFor="let editDVHeader of editDVHeaders">
                            {{editDVHeader}}
                        </div>
                    </div>
                    <div class="tableBody fixedHeight">                    
                        <ng-container formArrayName="fields">
                        <ng-container  *ngFor="let cntrl of fieldsControls(); let i = index; let columnIndex = index">
                        <div class="tableRow" [formGroupName]="i">                            
                            <div class="tdDiv">   
                                <input type="hidden" class="inputField" id="fieldName" formControlName="fieldName"  required readonly #fieldName>
                                <span>{{fieldName.value}}</span>
                            </div>
                            <div class="tdDiv">                                                                                                                                                                                      
                                <input type="text" class="inputField" id="displayName" formControlName="displayName"  required pattern="^[a-zA-Z0-9\s_-]+$">                                                                                                                                
                                <div *ngIf="cntrl.get('displayName').invalid && (cntrl.get('displayName').dirty || cntrl.get('displayName').touched)" class="errorText">
                                    <div *ngIf="cntrl.get('displayName').errors?.['required']">DisplayName is required</div>
                                    <div *ngIf="cntrl.get('displayName').errors?.['pattern']" class="errorText">DisplayName should contains only alphanumeric</div>
                                </div>
                            </div>
                            <label class="tdDiv">
                                <input type="checkbox" class="inputCheckbox" id="isFilter" formControlName="isFilter" >
                                <div class="styledCheckbox"></div>
                            </label>
                            <label class="tdDiv">
                                <input type="checkbox" class="inputCheckbox" id="isHidden" formControlName="isHidden" >
                                <div class="styledCheckbox"></div>
                            </label>
                        </div>
                    </ng-container>
                    </ng-container>
                    </div>
                </div>
            </div>      
            <div class="ctaCont">
                <button class="secondary" (click)="reset()">CANCEL</button>
                <button class="secondary_green_btn" type="submit" (click)="save()" [disabled]="
                viewForm?.status == 'INVALID' ||
                viewForm.invalid                
                "> SAVE CHANGES </button>
            </div>
           <!-- <pre>{{viewForm.value | json}}</pre> -->
        </form>                     
    </div>
    
    <div class="popUpCont dontShow">
        <div class="modalCont">
            <div class="modalHead">
                <h1>Are you sure?</h1>
            </div>
            <div class="modalContent">
                <p>
                    You are about to delete this access group. Once deleted, it cannot be recovered.
                </p>
                <p>
                    Do you want to proceed?
                </p>
            </div>
            <div class="modalFoot">
                <button class="secondary">Cancel</button>
                <button class="primary red">Yes, Delete</button>
            </div>
        </div>
    </div>
</div>


<app-success-toast
  [message]="successMessage"
  *ngIf="successMessage"
></app-success-toast>

<app-error-toast
  [message]="errorMessageSubmit"
  *ngIf="errorMessageSubmit"
></app-error-toast>
