import { Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataViewService {

    constructor(private router: Router,
    private http: HttpClient) {}

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;',
      }),
    };  

    getAllViews(searchText: any, sortBy: any, sortType: any, pageSize: any, page: any) {
      let userId = sessionStorage.getItem('userId');

      return this.http.post<any>( `${environment.apiUrl}/v1/getListOfViews?limit=${pageSize}&page=${page}`,
      { userId, searchText, sortBy, sortType },
      {});
    
    }
     

  getDataviewById(viewId: any) {
      let userId = sessionStorage.getItem('userId');

      return this.http.post<any>(
      `${environment.apiUrl}/v1/getViewFields`,
      { viewId, userId },
      {}
    );
  }

  saveView(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/v1/updateMetaData`, data, {});
  }

  deleteView(viewId: any) {    
    const userId = sessionStorage.getItem('userId');    

    return this.http.post<any>(
      `${environment.apiUrl}/v1/deleteView`,
      { viewId, userId },
      {}
    );  
  }

  refreshView() {
    const userId = sessionStorage.getItem('userId');   
    const todayDate = new Date().toISOString().slice(0, 10); 

    return this.http.post<any>(
      `${environment.apiUrl}/v1/getViews`,
      { userId: userId, startDate: "2024-10-01", endDate:  todayDate },
      {}
    );  
  }

  getListOfViews(payload: any) { return this.http.post<any>(`${environment.apiUrl}/v1/getListOfViews?page=1&limit=200`, payload, this.httpOptions); }
  deleteAccessGroup(payload: any) { return this.http.post<any>(`${environment.apiUrl}/v1/deleteAccessGroup`, payload, this.httpOptions); }
  getAllAccessGroups(payload: any, page: any, limit: any) { return this.http.post<any>(`${environment.apiUrl}/v1/getAllAccessGroups`,
     payload, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;',
      'page':page,
      'limit':limit
    }),
  }); }
  addUpdateAccessGroup(payload:any){
    return this.http.post<any>(`${environment.apiUrl}/v1/addUpdateAccessGroup`, payload, this.httpOptions);
  }
  getAllAccessGroupNames(payload:any){
    return this.http.post<any>(`${environment.apiUrl}/v1/getAllAccessGroups`, payload, this.httpOptions); 
  }
}
