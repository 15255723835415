import { Component, HostListener, Input, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/shared/util.service';
import { DataViewService } from '../edit-dataview/dataview.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
@Component({
  selector: 'admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {
  AGTableHeaders: any[] = ['Access Group Name', 'Dataviews Assigned', 'Actions']
  DVTableHeaders: any[] = ['View Name', 'Assigned Access Groups', 'Last Modified On', 'Created On', 'Actions']
  dataviews: any[] = []
  dataviews2: any[] = [{ name: 'test' }, { name: 'test' }, { name: 'test' }, { name: 'test' }, { name: 'test' }, { name: 'test' }]
  viewIdToBeDeleted: any;
  viewIdToBeDeletedAG: any;
  errorMessageSubmit: any;
  successMessage: string;
  pageSize = 10;
  p = 1;
  totalViews: any = [];
  totalViewsAG: any = [];
  searchTerm: any = '';
  AGTableData: any = [];
  otherListOptionFlag = false;
  optionActiveId: any = null;
  searchText: any = '';
  userId: any = null;
  sortBy = 'created_date';
  sortByForView = 'updated_date';
  sortType = 'desc';
  page = 1;
  limit = 10;
  isAscending = false
  activeSortIndex: any = null
  @Input() isManageDV = false
  @Input() isManageAG = true
  @Input() showEditAGpopup = false
  @Input() showCreateAGpopup = false
  @Input() showDeletepopup = false
  showConfirmPopup=false;
  accesGroupForm: FormGroup;
  currentPage = 1;
  itemsPerPage: number = 10
  headerText = signal('');
  listOfViews:any=null
  dataViewCount=0
  assignedView:any=null;
  showOthersPopup=false;
  existingGroupNames:any=null;
  currentEditingItem:any=null;
  listOfAllViews:any=null
  searchTxt=''
  cancelFlag=false;
  searchControl = new FormControl(''); 
  errText:string=null;
  viewNameToBeDeleted: any = '';
  constructor(private router: Router, private dataViewService: DataViewService, private spinner: NgxSpinnerService, private utilService: UtilService, private fb: FormBuilder) { }
  ngOnInit() {
    this.spinner.show();
    this.utilService.managedView.subscribe(res => {
       if(res){
         this.isManageDV = true;
         this.isManageAG= false;
       } else {
        this.isManageDV = false;
        this.isManageAG= true;
       }
    })
    this.dataViewService.getAllViews('', this.sortByForView, this.sortType, this.pageSize, this.p).subscribe(
      {
        next: (res: any) => {
          if (res.code === 400) {
            this.errText =  res?.result?.error || res?.message;
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 3000);
          } else {
            this.dataviews = res.result;
            this.totalViews = res.totalCount;
            this.spinner.hide();
            this.errorMessageSubmit = null;
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          this.errText =  err?.result?.error || err;
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
          this.utilService.goToTop();
        }
      });

    this.getAllAccessGroups();
    this.initForm();
  }
  initForm() {
    this.accesGroupForm = this.fb.group({
      "userId": [sessionStorage.getItem('userId')],
      "action": ["Add"],
      "groupId": [null],
      "groupName": ["",[Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')],[this.uniqueNameValidator()]],//^[a-zA-Z0-9]*$
      "assignedViews": this.fb.array([],[Validators.required])
    })
  }
  setListOfViews(item:any){
    this.listOfViews?.forEach((ele:any)=>{
      let index=item?.assignedViews?.findIndex((x:any)=>x?.id==ele?.viewId)
      if(index>-1){ele.isAssigned=true} 
    })
  }
  getListOfViews(item:any) {
    this.userId = sessionStorage.getItem('userId')
    let payload = { "userId": this.userId, "searchText": this.searchText, "sortBy": this.sortBy, "sortType": this.sortType };
    this.spinner.show();
    this.dataViewService.getListOfViews(payload)
      .subscribe({
        next: (res: any) => { if (res) { 
          this.listOfViews=res?.result?.map((item1:any)=>({viewId:item1?.viewId,viewName:item1?.viewName,isAssigned:false}));
          this.listOfAllViews=_.cloneDeep(this.listOfViews)          
          this.dataViewCount=this.listOfViews?.length || 0;
          if(item){
            this.setListOfViews(item)
          }
          this.setViewChips()
          
          this.spinner.hide(); 
        } }, error: (err: any) => {
          this.spinner.hide();
          this.errorMessageSubmit = err || err?.result?.error;
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
          this.utilService.goToTop();
        }
      })
  }
  getAllAccessGroups() {
    this.userId = sessionStorage.getItem('userId')
    let payload = { "userId": this.userId, "searchText": this.searchText, "sortBy": 'access_group_name', "sortType": this.sortType };
    this.dataViewService.getAllAccessGroups(payload, this.page, this.limit)
      .subscribe({
        next: (res: any) => { if (res) { this.AGTableData = res?.result; this.totalViewsAG = res?.rowCount; this.spinner.hide();this.errText=null } }, error: (err: any) => {
          this.spinner.hide();
          this.AGTableData=[];
          this.totalViewsAG=0;
          this.errText=err || err?.result?.error;
        }
      })

  }
  getDate(date: any): Date {
    return new Date(date);
  }
  filterData(searchTerm: any) {
    this.p = 1;
    this.filterTableData(searchTerm, this.sortByForView, this.sortType, this.pageSize, this.p);
  }
  filterTableData(searchTerm: any, sortBy: any, sortType: any, pageSize: any, currentPage: any) {
    this.spinner.show();
    this.dataViewService.getAllViews(searchTerm, sortBy, sortType, pageSize, currentPage).subscribe(
      {
        next: (res: any) => {
          if (res.code === 400) {
            this.errText =  res.message;
            this.spinner.hide();
            this.dataviews = [];
            this.totalViews = 0;
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 3000);
          } else {
            this.dataviews = res.result;
            this.totalViews = res.totalCount;
            this.errorMessageSubmit = null;
            this.spinner.hide();
            this.utilService.goToTop();
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          this.errText = err || err?.result?.error;
          this.dataviews = [];
          this.totalViews = 0;
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
          this.utilService.goToTop();
        },
      }
    );
  }
  getPage(event: any) {
    this.p = event;
    this.filterTableData(this.searchTerm, this.sortByForView, this.sortType, this.pageSize, this.p);
  }
  getPageAG(event: any) {
    this.page = event;
    this.getAllAccessGroups()
  }

  deleteAG() {
    this.showDeletepopup = !this.showDeletepopup
    this.viewNameToBeDeleted=null
  }

  deleteView() {
    this.showDeletepopup = false;    
    this.spinner.show();
    this.dataViewService.deleteView(this.viewIdToBeDeleted).subscribe(
      {
        next: (res: any) => {
          if (res.code === 400) {
            this.errorMessageSubmit = res.message;
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 3000);
          } else {
            const viewData = res;
            this.errorMessageSubmit = null;
            this.successMessage = `${viewData.result?.message}`;
            let filteredView = this.dataviews.filter(view => {
              return view.viewId != this.viewIdToBeDeleted;
            });

            this.dataviews = filteredView;
            this.spinner.hide();
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;
            }, 3000);
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          this.errorMessageSubmit = err || err?.result?.error;
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
          this.utilService.goToTop();
        },
      }
    );
  }
  deleteViewAG() {
    this.showDeletepopup = false;    
    this.spinner.show();
    let payload = {
      "userId": sessionStorage.getItem('userId'),
      "accessGroupId": this.viewIdToBeDeletedAG
    }
    this.dataViewService.deleteAccessGroup(payload).subscribe(
      {
        next: (res: any) => {
          if (res.code === 400) {
            this.errorMessageSubmit = res.message;
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 3000);
          } else {
            const viewData = res;
            this.errorMessageSubmit = null;
            this.successMessage = `${viewData.result}`;

            this.getAllAccessGroups()
            this.spinner.hide();
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;
            }, 3000);
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          this.errorMessageSubmit = err || err?.result?.error;
          this.spinner.hide();
          setTimeout(() => {
            this.errorMessageSubmit = null;
          }, 3000);
          this.utilService.goToTop();
        },
      }
    );
  }

  refreshView(){
    this.spinner.show();
    this.dataViewService.refreshView().subscribe({
      next: (res: any) =>{      
      this.successMessage = res?.message;
      this.spinner.hide();
      this.utilService.goToTop();
      this.p = 1; 
      this.filterTableData('', this.sortByForView, this.sortType, this.pageSize, this.p);
      setTimeout(() => {
        this.successMessage = null;
      }, 3000);
    },
    error: (err: any) =>{
      this.errorMessageSubmit = err || err?.result?.error;
      this.spinner.hide();
      setTimeout(() => {
        this.errorMessageSubmit = null;
      }, 3000);
    }
    });
  }

  deleteVD(viewId: any, viewName: any) {
    this.viewIdToBeDeleted = viewId;
    this.showDeletepopup = true;
    this.viewNameToBeDeleted = viewName;
  }
  showCreateAG() {
    this.showEditAGpopup = !this.showEditAGpopup
    this.createAG();
  }
  showEditAG() {
    this.showEditAGpopup = !this.showEditAGpopup;
    this.initForm();
    this.editAG();
    this.currentEditingItem=null;
    this.searchControl.reset()
  }
  editDV(id: number): void {
    this.router.navigate(['/edit-dataview', id]);
  }
  createAG() {
    this.headerText.set('Create Access Group');
  }
  editAG() {
    this.headerText.set('Edit Access Group');
  }
  toggleManageDV() {
    this.isManageDV = true;
    this.isManageAG = false;
  }
  toggleManageAG() {
    this.isManageDV = false;
    this.isManageAG = true;
  }
  showOtherGroups(index: any) {
    this.optionActiveId = index;
    this.otherListOptionFlag = !this.otherListOptionFlag
  }
  searchRecord() {
    this.getAllAccessGroups()
  }
  deleteVDAG(view: any) {
    this.viewIdToBeDeletedAG = view.groupId;
    // this.deleteItem=view.groupName
    this.viewNameToBeDeleted = view.groupName;
    this.showDeletepopup = true;
  }
  sortAg(i: any, headerName: any) {
    this.activeSortIndex = null;
    this.activeSortIndex = i;
    this.isAscending = !this.isAscending;
    this.page=1;
    if (this.isAscending) {
      this.sortType = 'asc'
    } else {
      this.sortType = 'desc'
    }
    this.getAllAccessGroups();
  }
  showPopupAG(action: any, item: any) {    
    this.getListOfViews(item);
    this.dataViewService.getAllAccessGroupNames({"userId":sessionStorage.getItem('userId'),"searchText":"","sortBy":"access_group_name","sortType":"desc"}).pipe(
      map((response:any) => response.result.map((item: any) => item.groupName))
    ).subscribe((groupNames) => {
      this.existingGroupNames = groupNames;
    });
    this.showEditAGpopup = true
    if (action == 'Add') {
      this.accesGroupForm.get('action').setValue(action)
      this.accesGroupForm.get('groupId').setValue(null)
    } else if (action == 'Edit') {
      this.currentEditingItem=item
      this.accesGroupForm.get('action').setValue(action)
      this.accesGroupForm.get('groupId').setValue(item.groupId)
      this.accesGroupForm.get('groupName').setValue(item.groupName)
      this.assignedView=item?.assignedViews;
      this.accesGroupForm.markAllAsTouched();
      this.accesGroupForm.markAsDirty();
      
    }
  }
  sortDV(i: any) {
    this.activeSortIndex = null;
    this.activeSortIndex = i;
    this.isAscending = !this.isAscending;
    this.sortType = this.isAscending ? 'asc' : 'desc';
    this.p = 1;
    this.filterTableData(this.searchTerm, this.sortByForView, this.sortType, this.pageSize, this.p);
  }
  toggleOtherPopup(){
    this.showOthersPopup=!this.showOthersPopup}
  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.showOthersPopup=false;
      this.otherListOptionFlag=false;
    }
  }
  @HostListener('document:click', ['$event'])
  closeShowMoreDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.otherListOptionFlag=false;
      this.showOthersPopup=false;
    }
  }
  unassignAll(){}
  onChangeDataview(dataview:any){
  }
  changeDataView(e: Event, dataview: any) {
    const checkbox = e.target as HTMLInputElement;
    dataview.isAssigned = checkbox.checked ? true : false;
    this.setViewChips()
  }
  get assignedViews(){
    return this.accesGroupForm.get('assignedViews') as FormArray
  }
  setViewChips() {
    this.assignedViews.clear()
    let views = this.listOfViews?.filter((item: any) => item?.isAssigned == true)?.map((item: any) => ({ id: item.viewId, displayViewHeading: item.viewName, isAssigned: true }))
    if(views?.length>0){
      views.forEach((item:any)=>{
        this.assignedViews.push(this.fb.group({ id: [item?.id], displayViewHeading: [item?.displayViewHeading], isAssigned: [true]}))
      })
      this.assignedView = _.cloneDeep(views)
    }else{
      this.assignedViews.clear()
      this.assignedView = []
    }
  }


  unsetViewChip(viewId:any){
    let views = this.listOfViews?.filter((item: any) => item?.isAssigned == true)?.map((item: any) => ({ id: item.viewId, displayViewHeading: item.viewName, isAssigned: true }))
    if(views?.length>0){
      let index=this.assignedViews.value.findIndex((item:any)=>item.id==viewId)
      if(index>-1) {
        this.assignedViews.removeAt(index)
      }
      this.assignedView = _.cloneDeep(views)
    }else{
      this.assignedViews.clear()
      this.assignedView = []
    }
  }
  uncheckView(viewId: any) {
    let index = this.listOfViews.findIndex((item: any) => item.viewId == viewId);
    if (index > -1) {this.listOfViews[index].isAssigned = false}
    this.unsetViewChip(viewId)
  }
  removeAllSelectedViews() {
    this.listOfViews.forEach((item: any) => {item.isAssigned = false;})
    this.assignedViews.clear()
    this.assignedView = []
  }
  saveUserGroup() {
    if (this.accesGroupForm.get('action').value == 'Add') {
      let payload = this.accesGroupForm.value
      this.addUpdateUserGroup(payload)
    } else if (this.accesGroupForm.get('action').value == 'Edit') {
      let payload = this.accesGroupForm.value
      this.addUpdateUserGroup(payload)
    }
  }
  addUpdateUserGroup(payload: any) {
    this.spinner.show();
    this.dataViewService.addUpdateAccessGroup(payload)
      .subscribe(
        {
          next: (res: any) => {
            this.showEditAG()
            this.errorMessageSubmit = null;
            this.successMessage = res?.result;
            setTimeout(() => {
              this.successMessage = null;
            }, 3000);
            this.getAllAccessGroups()
            this.spinner.hide();
          },
          error: (err: any) => {
            this.spinner.hide();
            this.errorMessageSubmit = err || err?.result?.error;
            this.spinner.hide();
            setTimeout(() => {
              this.errorMessageSubmit = null;
            }, 3000);
            this.utilService.goToTop();
          }
        }
      )
  }
  capitalizeTextOnKeyup() {
    const nameControl = this.accesGroupForm.get('groupName');
    if (nameControl) {
      const capitalizedValue = nameControl.value?.toUpperCase();
      nameControl.setValue(capitalizedValue);
    }
  }
  get groupName() {
    return this.accesGroupForm.get('groupName') as FormControl;
  }
  uniqueNameValidator() {
    return (control: any) => {
      return new Promise(resolve => {
        setTimeout(() => {
          if(this.accesGroupForm.get('action').value=='Edit' && control?.value?.toLowerCase()==this.currentEditingItem?.['groupName']?.toLowerCase()){
            resolve(null); 
          }
          const isNameTaken = this.existingGroupNames.some((item:any) => item?.toLowerCase() === control?.value?.toLowerCase());
          if (isNameTaken) {
            resolve({ uniqueName: true }); 
          } else {
            resolve(null); 
          }
        }, 500);
      });
    };
  }
  filterViews(event: any) {
    let searchTxt = event.target.value
    if (searchTxt != "") {
      let views = _.cloneDeep(this.listOfAllViews)
      this.listOfViews = views.filter((item: any) => item.viewName?.toLowerCase()?.includes(searchTxt.toLowerCase()))
    } else {
      if (this.currentEditingItem) {
        this.listOfViews = _.cloneDeep(this.listOfAllViews)
        this.listOfViews?.forEach((ele: any) => {
          let index = this.assignedView?.findIndex((x: any) => x?.id == ele?.viewId)
          if (index > -1) { ele.isAssigned = true }
        })
      } else {
        this.listOfViews = _.cloneDeep(this.listOfAllViews)
      }
    }
  }
  cancelForm(){
    this.showConfirmPopup=true;
  }
  closeConfimPopup(){
    this.showConfirmPopup=false
  }
  resetForm() {
    if (this.accesGroupForm.get('action').value == 'Add') {
      this.accesGroupForm.reset();
      this.assignedView = []
      this.listOfViews = _.cloneDeep(this.listOfAllViews)
      this.showConfirmPopup = false
      this.cancelFlag = false
    } else if (this.accesGroupForm.get('action').value == 'Edit') {
      this.accesGroupForm.get('groupName').setValue(this.currentEditingItem.groupName)
      this.assignedView=this.currentEditingItem?.assignedViews
      this.assignedView?.forEach((item:any)=>{
        this.assignedViews.push(this.fb.group({ id: [item?.id], displayViewHeading: [item?.displayViewHeading], isAssigned: [true]}))
      })
      this.listOfViews?.forEach((ele: any) => {
        let index = this.assignedView?.findIndex((x: any) => x?.id == ele?.viewId)
        if (index > -1) { ele.isAssigned = true }
      })
      this.showConfirmPopup = false
      this.cancelFlag = false
    }
  }
  checkAssignedView() {
    if (this.assignedView?.length > 0) {
      this.accesGroupForm.markAsDirty();
      return false
    } else {
      return !this.accesGroupForm.dirty
    }
  }
  clearSearch() {
    this.searchText = ''; 
    this.getAllAccessGroups()
  }

  clearSearchForView() {
    this.searchTerm = '';
    this.p = 1; 
    this.filterTableData(this.searchTerm, this.sortByForView, this.sortType, this.pageSize, this.p);
  }
}
