import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilService } from './util.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private utilService: UtilService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        let errorMessage: any = '';

        if (error.error.code === 404 || error.error.message === 'Not Found' 
        || error.error.code === 400) {
          errorMessage = error.error.result.error;
          this.utilService.SharingError.next(errorMessage);
        }

        return throwError(errorMessage == '' ? error.message : errorMessage);
      })
    );
  }
}
