<div class="appContainer">
    <div class="wrpr">
        <h1>Data Quest</h1>
        <div class="contentCard">
            <div class="pageHead">
                <button class="backBtn">
                    <img src="/assets/images/backBtn.svg" alt="">
                </button>
                <div class="iconHeading">
                    <img src="/assets/images/settings-green.svg" alt="">
                    <h2>Admin Settings</h2>
                </div>
            </div>
            <ul class="tabCont">
                <li>
                    <button class="tabBtn" (click)="toggleManageAG()" [ngClass]="{
                        'active': isManageAG
                    }">
                        MANAGE ACCESS GROUP(s)
                    </button>
                </li>
                <li>
                    <button class="tabBtn" (click)="toggleManageDV()" [ngClass]="{
                        'active': isManageDV
                    }">
                        MANAGE DATAVIEW(s)
                    </button>
                </li>
            </ul>
            <div class="sectionHead sb search-container" *ngIf="isManageAG" >                
                <div class="search-con">
                    <button *ngIf="searchText" class="clear-btn" (click)="clearSearch()">&#x2715;</button>
                    <input type="search" [(ngModel)]="searchText" #search="ngModel" class="search inputField" placeholder="Search"
                        (keyup.enter)="searchRecord()">
                    
                </div>
                <button class="secondary" (click)="showPopupAG('Add',null)">
                    CREATE NEW
                </button>
            </div>
            <div class="sectionHead sb  search-container" *ngIf="isManageDV">
                <div class="search-con">
                    <button *ngIf="searchTerm" class="clear-btn" (click)="clearSearchForView()">&#x2715;</button>
                    <input type="search" [(ngModel)]="searchTerm" #search="ngModel" class="search inputField"
                    placeholder="Search" (keyup.enter)="filterData(search.value)">
                </div>                                                                                                
                <ng-container *ngIf="!isManageDV"></ng-container>
                <ng-container *ngIf="isManageDV">
                    <button class="secondary" (click)="refreshView()">
                        <img src="/assets/images/refresh-green.svg" alt="">
                        Refresh
                    </button>
                </ng-container>
            </div>
            <p class="itemCounter">Showing results</p>
            <div class="tabContent" *ngIf="isManageAG">
                <div class="tableCont" [ngClass]="{'manageDV': isManageDV}">
                    <div class="tableHead">
                        <div class="thDiv"
                            *ngFor="let headerName of (isManageAG ? AGTableHeaders : DVTableHeaders);let i=index">
                            {{headerName}}
                            <span class="down-arrow-icon" [class.rotate]="isAscending && activeSortIndex==i"
                                (click)="sortAg(i,headerName)"
                                *ngIf="headerName=='Access Group Name'">
                                <img src="../../../../assets/images//chevron-down.svg" alt="chevron_icon" /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tabContent" *ngIf="isManageDV">
                <div class="tableCont" [ngClass]="{'manageDV': isManageDV}">                    
                    <div class="tableHead">
                        <div class="thDiv" *ngFor="let headerName of (isManageAG ? AGTableHeaders : DVTableHeaders);let i=index;">
                            {{headerName}}
                            <span class="down-arrow-icon" [class.rotate]="isAscending && activeSortIndex==i"
                            (click)="sortDV(i)"
                            *ngIf="headerName=='Last Modified On'">
                            <img src="../../../../assets/images//chevron-down.svg" alt="chevron_icon" /></span>
                        </div>
                    </div>                
                </div>
            </div>
            <div class="tabContent fixedHeight" *ngIf="isManageAG">
                <div class="tableCont" [ngClass]="{'manageDV': isManageDV}" *ngIf="AGTableData?.length>0">
                    <div class="tableRow" *ngFor="let item of AGTableData| paginate: {
                        id: 'listing_pagination',
                        itemsPerPage: limit,
                        currentPage: page,
                        totalItems: totalViewsAG
                    };let i=index">
                        <div class="tdDiv">{{item.groupName}}</div>
                        <div class="tdDiv  tagSet">
                            <div class="tag" *ngFor="let accessGroup of item.assignedViews?.slice(0,3)">
                                {{accessGroup.displayViewHeading}}
                            </div>
                            <span class="others dropdown-container" *ngIf="item.assignedViews?.slice(3)?.length>0" >
                                <small (click)="showOtherGroups(i)">+{{item.assignedViews?.slice(3)?.length}}
                                    others</small>
                                <div class="othersList" *ngIf="otherListOptionFlag && optionActiveId==i">
                                    <div *ngFor="let group of item.assignedViews?.slice(3)">
                                        <p>{{group.displayViewHeading}}</p>
                                    </div>
                                </div>
                            </span>
                        </div>
                        
                        <div class="tdDiv">
                            <button type="button" class="editBtn" title="Edit">
                                <img src="/assets/images/edit-icon.svg" alt="Edit" (click)="showPopupAG('Edit',item)">
                            </button>
                            <button type="button" class="trashBtn" title="Delete" (click)="deleteVDAG(item)">
                                <img src="/assets/images/trash-icon.svg" alt="Delete">
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="AGTableData?.length==0" class="errorBlock">
                    {{errText}}
                </div>
            </div>
            <div class="tabContent fixedHeight" *ngIf="isManageDV">                            
                <div class="tableCont" [ngClass]="{'manageDV': isManageDV}">                    
                    <div class="tableRow" *ngFor="let item of dataviews | paginate: {
                        id: 'listing_pagination',
                        itemsPerPage: 10,
                        currentPage: p,
                        totalItems: totalViews
                    };let i=index">
                        <div class="tdDiv">
                            {{item.viewName}}
                        </div>
                        <div class="tdDiv  tagSet" [ngClass]="{'dontShow': isManageAG}">
                            <div class="tag" *ngFor="let accessGroup of item.assignedAccessGroupsList.slice(0,3)">
                                {{accessGroup.accessGroupName}}
                            </div>
                            <div class="dropdown-container">
                                <span class="others" *ngIf="item.assignedAccessGroupsList?.slice(3)?.length>0">
                                    <small (click)="showOtherGroups(i)">+{{item.assignedAccessGroupsList?.slice(3)?.length}}
                                        others</small>                                                                    
                                    <div class="othersList" *ngIf="otherListOptionFlag && optionActiveId==i">
                                        <div *ngFor="let group of item.assignedAccessGroupsList?.slice(3)">
                                            <p>{{group.accessGroupName}}</p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                       
                       
                        <div class="tdDiv">
                            {{getDate(item.modifiedDate) | date: 'dd/MM/yyyy'}}
                        </div>
                        <div class="tdDiv" [ngClass]="{'dontShow': false}">
                            {{getDate(item.createdOn) | date: 'dd/MM/yyyy'}}
                        </div>                                                                        
                        <div class="thDiv">
                            <button type="button" class="editBtn" title="Edit"
                                (click)="isManageAG ? showEditAG(): editDV(item.viewId)">
                                <img src="/assets/images/edit-icon.svg" alt="Edit">
                            </button>
                            <button type="button" class="trashBtn" title="Delete" (click)="deleteVD(item.viewId, item.viewName)">
                                <img src="/assets/images/trash-icon.svg" alt="Delete">
                            </button>
                        </div>
                    </div>                                                            
                </div>
                <div *ngIf="dataviews?.length==0" class="errorBlock">
                    {{errText}}
                </div>                                            
            </div>
            <div class="paginationCont" *ngIf="isManageAG">
                <ng-container *ngIf="AGTableData?.length>0">
                    <pagination-controls id="listing_pagination" (pageChange)="getPageAG($event)" previousLabel="Prev" nextLabel="Next">
                    </pagination-controls>
                </ng-container>
            </div>
            <div class="paginationCont" *ngIf="isManageDV">
                <ng-container *ngIf="dataviews?.length>0">
                    <pagination-controls id="listing_pagination" (pageChange)="getPage($event)" previousLabel="Prev"
                    nextLabel="Next">
                    </pagination-controls>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="popUpCont" [ngClass]="{ 'dontShow' : !showEditAGpopup }">
     
        <form [formGroup]="accesGroupForm" (ngSubmit)="saveUserGroup()">
            <div class="modalCont">
                <div class="modalHead">
                    <h1>{{headerText()}}</h1>
                    <img (click)="showEditAG()" src="/assets/images/closeIcon-16x16.svg" alt="">
                </div>
                <div class="modalContent">
                    <div class="formControl">
                        <label for="agName" class="required">Access Group Name</label>
                        <input type="text" class="inputField" placeholder="Type here" [required]="true" formControlName="groupName" (keyup)="capitalizeTextOnKeyup()" >
                        <div *ngIf="groupName?.touched && groupName?.invalid" class="error">
                            <div *ngIf="groupName?.errors?.['required']">Access Group Name is required.</div>
                            <div *ngIf="groupName?.errors?.['pattern']">Only alphanumeric characters are allowed.</div>
                            <div *ngIf="groupName?.errors?.['uniqueName']">This Access Group Name is already exist. Please enter another name.</div>
                          </div>
                    </div>
                    <div class="selections">
                        <div class="label required">Dataviews assigned</div>
                        <button type="button" class="unassBtn" type="button" *ngIf="assignedView?.length>0" (click)="removeAllSelectedViews()" tabindex="-1">Unassign all</button>
                        <div class="noSelections" *ngIf="assignedView?.length==0 || assignedView==null">
                            <img src="/assets/images/alert-icon.svg" alt="">
                            <p>
                                Please assign dataviews to the access group in order to save.
                            </p>
                        </div>
                        <div class="popupWidth"></div>
                        <div class="selectedDVs" *ngIf="assignedView?.length>0">
                    
                            <span class="pill" *ngFor="let dataview of assignedView?.slice(0,4)">
                                {{dataview.displayViewHeading}}
                                <button type="button"><img src="/assets/images/closeIcon-16x16.svg" alt="" (click)="uncheckView(dataview.id)"></button>
                            </span>
                            
                           <div class="dropdown-container">
                            <button type="button" class="showOthers" *ngIf="assignedView?.slice(4)?.length>0" (click)="toggleOtherPopup()">
                                + {{assignedView?.slice(4)?.length}} others
                            </button>
                            <div class="otherSelected" *ngIf="showOthersPopup">
                                <p *ngFor="let dv of assignedView?.slice(4)" >
                                    {{dv.displayViewHeading}}
                                    <img src="/assets/images/closeIcon-16x16.svg" alt="" (click)="uncheckView(dv.id)">
                                </p>
                            </div>
                           </div>
                        </div>
                    </div>
                    <div class="dvGroup">
                        <input type="text" class="search inputField" [formControl]="searchControl"  placeholder="Search dataviews" (keyup)="filterViews($event)">
                        <div class="scrollGroup">
                            <label for="{{dataview?.viewName?.toLowerCase()}}" *ngFor="let dataview of listOfViews" (click)="onChangeDataview(dataview)">
                                <input type="checkbox" name="dataviews" id="{{dataview?.viewName?.toLowerCase()}}" (change)="changeDataView($event,dataview)"  [checked]="dataview.isAssigned">
                                <span class="styledCheckbox"></span>
                                {{dataview?.viewName}}
                            </label>
                        </div>
                    </div>
                    <p class="count">Showing {{dataViewCount}} Dataviews</p>
                </div>
                <div class="modalFoot">
                    <button class="secondary" type="button" (click)="cancelForm()" [disabled]="checkAssignedView() ||!this.accesGroupForm.dirty" >Cancle</button>
                    <button class="secondary" type="submit" [disabled]="accesGroupForm?.status=='INVALID'">SAVE</button>
                </div>
            </div>
        </form>
    </div>
    <div class="popUpCont" [ngClass]="{ 'dontShow' : true }">
        <div class="modalCont">
            <div class="modalHead">
                <h1></h1>
                <img src="/assets/images/closeIcon-16x16.svg" alt="">
            </div>
            <div class="modalContent">
                <div class="formControl">
                    <label for="agName" class="required">Access Group Name</label>
                    <input type="text" class="inputField" placeholder="Type here" [required]="true">
                </div>
                <div class="selections">
                    <div class="label required">Dataviews assigned</div>
                    <button class="unassBtn">Unassign all</button>
                    <div class="noSelections">
                        <img src="/assets/images/alert-icon.svg" alt="">
                        <p>
                            Please assign dataviews to the access group in order to save.
                        </p>
                    </div>
                    <div class="selectedDVs">
                        <span class="pill">
                            <button><img src="/assets/images/closeIcon-16x16.svg" alt=""></button>
                        </span>
                        <button class="showOthers">
                            +2 others
                        </button>
                        <div class="otherSelected">
                            <p>
                                Port and Airplane Vehicles
                                <img src="/assets/images/closeIcon-16x16.svg" alt="">
                            </p>
                            <p>
                                Port and Airplane Vehicles
                                <img src="/assets/images/closeIcon-16x16.svg" alt="">
                            </p>
                        </div>
                    </div>
                </div>
                <div class="dvGroup">
                    <input type="text" class="search inputField" placeholder="Search dataviews">
                    <div class="scrollGroup">
                        <label>
                            <input type="checkbox" name="dataviews">
                            <span class="styledCheckbox"></span>

                        </label>
                    </div>
                </div>
                <p class="count">Showing 78 Dataviews</p>
            </div>
            <div class="modalFoot">
                <div></div>
                <button class="primary">SAVE</button>
            </div>
        </div>
    </div>
    <div class="popUpCont" [ngClass]="{ 'dontShow' : !showDeletepopup }">
        <div class="modalCont">
            <div class="modalHead">
                <h1>Are you sure?</h1>
            </div>
            <div class="modalContent">
                <p>
                    You are about to delete this {{isManageAG?'Access Group Name': 'View'}} <b>{{viewNameToBeDeleted}}</b> and associated access group. Once deleted, it cannot be recovered.
                </p>
                <p>
                    Do you want to proceed?
                </p>
            </div>
            <div class="modalFoot">
                <button class="secondary" (click)="deleteAG()" >Cancel</button>
                <button class="primary red" (click)="deleteView()" *ngIf="isManageDV">Yes, Delete</button>
                <button class="primary red" (click)="deleteViewAG()" *ngIf="isManageAG">Yes, Delete</button>
            </div>
        </div>
    </div>
    <div class="popUpCont" [ngClass]="{ 'dontShow' : !showConfirmPopup }">
        <div class="modalCont">
            <div class="modalHead">
                <h1>Confirmation</h1>
            </div>
            <div class="modalContent">
                <p>
                    This action will reset all the fields.
                </p>
                <p>
                    Do you want to proceed?
                </p>
            </div>
            <div class="modalFoot">
                <button class="secondary" (click)="closeConfimPopup()">No</button>
                <button class="primary red" (click)="resetForm()">Yes</button>
            </div>
        </div>
    </div>
</div>
<app-success-toast [message]="successMessage" *ngIf="successMessage"></app-success-toast>
<app-error-toast [message]="errorMessageSubmit" *ngIf="errorMessageSubmit"></app-error-toast>