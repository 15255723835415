import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  SharingError = new Subject();
  managedView = new BehaviorSubject(false);

  constructor() {}

  autoScrollUp() {
    window.scroll({
      top: 320,
      left: 0,
      behavior: 'smooth',
    });
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  setManagedView(isManagedView: any){
    this.managedView.next(isManagedView);
  }
}
