import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { DetailsComponent } from './core/components/details/details.component';
import { EditDataviewComponent } from './core/components/edit-dataview/edit-dataview.component';
import { AdminSettingsComponent } from './core/components/admin-settings/admin-settings.component';


const routes: Routes = [
  { path: 'jobrequest/:id', component: DetailsComponent },
  { path: 'edit-dataview/:id', component: EditDataviewComponent }, 
  { path: 'admin-settings', component: AdminSettingsComponent },  
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    component: HomeComponent,
  },
  { path: 'details/:id', component: DetailsComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
